<template>
    <div>
        <div class="row">
            <div class="col">
                <div class="alert alert-info">Het laden van deze pagina kan even duren omdat er veel data ingeladen moet worden.<br/>
                    Adresgegevens van een TOPdesk vestiging kunnen maximaal één uur oud zijn.</div>
            </div>
        </div>
        <div class="row">
            <div class="loading-overlay" v-show="loading">
                <div class="spinner-border" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="col">
                <h1>Gekoppelde debiteuren</h1>
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th>Exact debiteur</th>
                        <th>Topdesk vestiging</th>
                        <th>Ontkoppelen?</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(debtor, i) in debtors.already_matched">
                        <td><b>{{ debtor.exact.Name }}</b><br/>
                            {{debtor.exact.AddressLine1}}<br/>
                            {{debtor.exact.Postcode}} {{debtor.exact.City}}</td>
                        <td><b>{{debtor.topdesk.name}}</b><br/>
                            {{debtor.topdesk.address.street}} {{debtor.topdesk.address.number}}<br/>
                            {{debtor.topdesk.address.postcode}} {{debtor.topdesk.address.city}}</td>
                        <td><button class="btn btn-danger" @click="unsync(debtor)">Ontkoppel</button></td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="col">
                <form @submit.prevent="submitSuggestedMatches">
                    <h1>Voorgestelde matches</h1>
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th><input type="checkbox" v-model="checkAllSuggestedMatches"></th>
                            <th>Exact debiteur</th>
                            <th>Topdesk vestiging</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(debtor, i) in debtors.suggested_matches">
                            <td><input type="checkbox" v-model="debtor.checked"></td>
                            <td><b>{{ debtor.exact.Name }}</b><br/>
                            {{debtor.exact.AddressLine1}}<br/>
                            {{debtor.exact.Postcode}} {{debtor.exact.City}}</td>
                            <td><b>{{debtor.topdesk.name}}</b><br/>
                                {{debtor.topdesk.address.street}} {{debtor.topdesk.address.number}}<br/>
                                {{debtor.topdesk.address.postcode}} {{debtor.topdesk.address.city}}</td>
                        </tr>
                        </tbody>
                    </table>
                    <input type="submit" class="btn btn-success" value="Koppelen">
                </form>
            </div>

            <div class="col">
                <h1>Niet gekoppelde Exact debiteuren</h1>
                <form @submit.prevent="submitUnmatchedExact">
                <table class="table table-striped">
                    <thead>
                    <tr>
                        <th>Exact debiteur</th>
                        <th>TOPdesk Vestiging</th>
                        <th><input type="checkbox" v-model="checkAllUnmatchedExact"></th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(debtor, i) in debtors.unmatched_exact">
                        <td><b>{{ debtor.Name }}</b><br/>
                            {{debtor.AddressLine1}}<br/>
                            {{debtor.Postcode}} {{debtor.City}}
                        </td>
                        <td>
                            <select class="form-control" v-model="debtor.topdeskId">
                                <option>[ Selecteer ]</option>
                                <option v-for="branch in debtors.unmatched_topdesk" :value="branch.id">{{ branch.name }} - {{branch.address.city}}</option>
                            </select>
                        </td>
                        <td><input type="checkbox" v-model="debtor.checked"></td>
                    </tr>
                    </tbody>
                </table>
                <input type="submit" class="btn btn-success" value="Koppelen">
                </form>
            </div>
            <div class="col">
                <h1>Niet gekoppelde TOPdesk vestigingen</h1>
                <form @submit.prevent="submitUnmatchedTopdesk">
                    <table class="table table-striped">
                        <thead>
                        <tr>
                            <th>TOPdesk Vestiging</th>
                            <th>Exact debiteur</th>
                            <th><input type="checkbox" v-model="checkAllUnmatchedTopdesk"></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="(branch, i) in debtors.unmatched_topdesk">
                            <td><b>{{branch.name}}</b><br/>
                                {{branch.address.street}} {{branch.address.number}}<br/>
                                {{branch.address.postcode}} {{branch.address.city}}</td>
                            <td>
                                <select class="form-control" v-model="branch.exactCode">
                                    <option value="">[ Selecteer ]</option>
                                    <option v-for="debtor in debtors.unmatched_exact" :value="debtor.Code">{{ debtor.Name }} - {{debtor.City}}</option>
                                </select>
                            </td>
                            <td><input type="checkbox" v-model="branch.checked"></td>
                        </tr>
                        </tbody>
                    </table>
                <input type="submit" class="btn btn-success" value="Koppelen">
                </form>
            </div>
        </div>
    </div>
</template>


<script>
    import mix from './../settings/mixin';
    import qs from 'qs';

    export default {
        mounted() {
            this.getApiConfig('topdesk').then((response) => {
                if (response.data.config) {
                    this.getDebtors();
                    this.getTopdeskBranches();
                }
            });
        },
        destroyed() {
            if (this.timeout) {
                clearTimeout(this.timeout);
            }
        },
        mixins: [mix],
        data() {
            return {
                timeout: undefined,
                debtors: [],
                checkAllSuggestedMatches: false,
                checkAllUnmatchedExact: false,
                checkAllUnmatchedTopdesk: false,
                exactDebtors: [],
                topdeskBranches: [],
                api_config: {},
                loading: true,
            }
        },
        watch: {
            checkAllSuggestedMatches(val) {
                this.debtors.suggested_matches.map((debtor) => {
                    debtor.checked = val;
                });
            },
            checkAllUnmatchedExact(val) {
                this.debtors.unmatched_exact.map((debtor) => {
                    debtor.checked = val;
                });
            },
            checkAllUnmatchedTopdesk(val) {
                this.debtors.unmatched_topdesk.map((debtor) => {
                    debtor.checked = val;
                });
            }
        },
        methods: {
            getDebtors() {
                this.loading = true;
                return this.$http.get('/debtors/list').then((response) => {
                    this.debtors = response.data;
                    this.loading = false;
                });
            },
            getTopdeskBranches() {
                return this.$http.get('/topdesk/api/branches').then((response) => {
                    this.topdeskBranches = response.data;
                });
            },
            submitSuggestedMatches() {
                let checkedMatches = this.debtors.suggested_matches.filter((match) => {
                    return match.checked;
                });
                return this.$http.post('/debtors/sync', {sync:checkedMatches}).then((response) => {
                    this.$toastr.s('Vestigingen opgeslagen.');
                    this.getDebtors();
                });
            },
            submitUnmatchedExact(debtor) {
                let checkedDebtors = this.debtors.unmatched_exact.filter((debtor) => {
                    return debtor.checked;
                }).map((debtor) => {
                    return {
                        topdesk: {
                            id:debtor.topdeskId
                        },
                        exact: {
                            Code: debtor.Code
                        }
                    };
                });

                return this.$http.post('/debtors/sync', {sync:checkedDebtors}).then((response) => {
                    this.$toastr.s('Vestigingen opgeslagen.');
                    this.getDebtors();
                });
            },
            submitUnmatchedTopdesk(debtor) {
                let checkedBranches = this.debtors.unmatched_topdesk.filter((branch) => {
                    return branch.checked;
                }).map((branch) => {
                    return {
                        topdesk: {
                            id:branch.id
                        },
                        exact: {
                            Code: branch.exactCode
                        }
                    };
                });

                return this.$http.post('/debtors/sync', {sync:checkedBranches}).then((response) => {
                    this.$toastr.s('Vestigingen opgeslagen.');
                    this.getDebtors();
                });
            },
            unsync(debtor) {
                return this.$http.post('/debtors/unsync', {unsync:[debtor]}).then((response) => {
                    this.$toastr.s('Debiteur ontkoppeld.');
                    this.getDebtors();
                });
            }
        }
    }
</script>
